import Button from '@mui/material/Button';
import { Theme, SxProps } from '@mui/material/styles';

import { paths } from 'src/routes/paths';
import { RouterLink } from 'src/routes/components';

import { useTranslate } from 'src/locales';
import { useAuthContext } from 'src/auth/hooks/use-auth-context';

// ----------------------------------------------------------------------

type Props = {
  sx?: SxProps<Theme>;
};

export default function ProfileButton({ sx }: Props) {
  const { t } = useTranslate();
  const { user } = useAuthContext();
  const profileLink = user?.role === 'student' ? paths.student.account : paths.staff.account;

  return (
    <Button
      component={RouterLink}
      href={profileLink}
      variant="text"
      sx={{
        color: 'primary.contrastText',
        m: 0,
        p: 0,
        justifyContent: 'flex-end',
        minWidth: 'max-content',
        lineHeight: '15px',
        fontSize: '14px',
        fontWeight: 900,
        '&.MuiButton-root:hover': {
          backgroundColor: 'transparent',
        },
        '& .MuiButton-startIcon': {
          marginRight: '12px',
        },
        ...sx,
      }}
    >
      {`${t('home_page.home_view.hello')} ${user?.firstName}`}
    </Button>
  );
}
