import { PopupButton } from '@typeform/embed-react';

import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import { useTheme } from '@mui/material/styles';
import Container from '@mui/material/Container';

import { paths } from 'src/routes/paths';
import { useRouter } from 'src/routes/hooks';

import { useOffSetTop } from 'src/hooks/use-off-set-top';
import { useResponsive } from 'src/hooks/use-responsive';

import { useTranslate } from 'src/locales';
// import { bgBlur } from 'src/theme/css';

import { ENVIRONMENTS } from 'src/constants/misc';
import { USER_ROLES } from 'src/constants/user-roles';
import { TYPEFORM, ENVIRONMENT } from 'src/config-global';
import { useAuthContext } from 'src/auth/hooks/use-auth-context';

import Logo from 'src/components/logo';
import { BANNER_HEIGHT_OFFSET } from 'src/components/banner';
import CustomButton from 'src/components/custom-button/custom-button';

import { premisesSectionId } from 'src/sections/student/account/account-premises';

import NavMobile from './nav/mobile';
import NavDesktop from './nav/desktop';
import { HEADER } from '../config-layout';
import LoginButton from '../common/login-button';
import { useHeaderNavData } from './header-config';
import HeaderShadow from '../common/header-shadow';
import LogoutButton from '../common/logout-button';
import ProfileButton from '../common/profile-button';
import DashboardButton from '../common/dashboard-button';

// ----------------------------------------------------------------------

export default function Header() {
  const theme = useTheme();
  const { authenticated, user } = useAuthContext();
  const navData = useHeaderNavData();
  const { t } = useTranslate();
  const router = useRouter();

  const mdUp = useResponsive('up', 'md');

  const offsetTop = useOffSetTop(HEADER.H_DESKTOP);

  const handleContactBtnClick = () => {
    if (user?.role !== USER_ROLES.STUDENT) return;
    router.push(`${paths.student.account}#${premisesSectionId}`);
  };

  return (
    <AppBar
      color="primary"
      sx={{ top: ENVIRONMENT !== ENVIRONMENTS.prod ? BANNER_HEIGHT_OFFSET : 0 }}
      position="relative"
    >
      <Toolbar
        disableGutters
        sx={{
          height: {
            xs: HEADER.H_MOBILE,
            md: HEADER.H_DESKTOP,
          },
          transition: theme.transitions.create(['height'], {
            easing: theme.transitions.easing.easeInOut,
            duration: theme.transitions.duration.shorter,
          }),
          // ...(offsetTop && {
          //   ...bgBlur({
          //     color: theme.palette.background.default,
          //   }),
          //   height: {
          //     md: HEADER.H_DESKTOP_OFFSET,
          //   },
          // }),
        }}
      >
        <Container
          sx={{
            height: 1,
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            gap: '30px',
            m: 0,
            '&.MuiContainer-root': {
              maxWidth: '100%',
              paddingX: '32px',
            },
          }}
        >
          <Box sx={{ flexShrink: '1', justifyItems: 'center' }}>
            <Logo />
          </Box>

          {mdUp && <Box sx={{ flexGrow: 1 }} />}

          {mdUp && (
            <Stack alignItems="end" direction="column" spacing={3}>
              <Stack direction="row" spacing={5}>
                {authenticated && <ProfileButton />}
                {authenticated && user?.role !== USER_ROLES.STUDENT && <DashboardButton />}
                {authenticated ? <LogoutButton /> : <LoginButton />}
              </Stack>
              <Stack alignItems="center" direction="row" spacing={2}>
                <Stack alignItems="center" direction={{ xs: 'row' }} spacing={2}>
                  <NavDesktop data={navData} />
                  <CustomButton
                    customvariant="contact"
                    id={TYPEFORM.formId}
                    component={user?.role !== USER_ROLES.STUDENT ? PopupButton : undefined}
                    onClick={user?.role === USER_ROLES.STUDENT ? handleContactBtnClick : undefined}
                  >
                    {t('common.contact_us').toUpperCase()}
                  </CustomButton>
                </Stack>
              </Stack>
            </Stack>
          )}
          {!mdUp && (
            <Box
              sx={{
                position: 'absolute',
                top: 0,
                right: 30,
                width: '48px',
                height: '48px',
                backgroundColor: theme.palette.secondary.main,
              }}
            >
              <NavMobile data={navData} handleContactBtnClick={handleContactBtnClick} />
            </Box>
          )}
        </Container>
      </Toolbar>

      {offsetTop && <HeaderShadow />}
    </AppBar>
  );
}
