import React from 'react';

import { styled } from '@mui/material/styles';
import Button, { ButtonProps } from '@mui/material/Button';

interface CustomButtonProps extends ButtonProps {
  customvariant:
    | 'contact'
    | 'signin'
    | 'outlined'
    | 'contained'
    | 'containedDark'
    | 'colorContained'
    | 'retry';
  bgColor?: string | undefined;
}

const StyledButton = styled(Button)<{
  customvariant:
    | 'contact'
    | 'signin'
    | 'outlined'
    | 'contained'
    | 'containedDark'
    | 'colorContained'
    | 'retry';
  bgColor: string | undefined;
}>(({ theme, customvariant, bgColor }) => ({
  ...(customvariant === 'contact' && {
    lineHeight: '0.875rem',
    fontSize: '0.875rem',
    borderRadius: '2rem',
    letterSpacing: '0.1rem',
    textTransform: 'uppercase',
    color: '#ffffff',
    backgroundColor: theme.palette.customColors.custom1,
    '&:hover': {
      backgroundColor: theme.palette.secondary.dark,
    },
    '@media (min-width: 768px)': {
      backgroundColor: theme.palette.secondary.main,
    },
  }),
  ...(customvariant === 'signin' && {
    minHeight: '64px',
    fontSize: '16px',
    lineHeight: '20px',
    letterSpacing: '0.2px',
    backgroundColor: '#ffffff',
    borderRadius: '16px',
    boxShadow: theme.customShadows.button,
    color: theme.palette.grey[900],
    '&:hover': {
      backgroundColor: theme.palette.grey[300],
    },
  }),
  ...(customvariant === 'outlined' && {
    border: '2px solid #ffffff',
    letterSpacing: '0.1rem',
    borderRadius: '2rem',
    textTransform: 'uppercase',
    color: '#ffffff',
  }),
  ...(customvariant === 'contained' && {
    color: '#ffffff',
    letterSpacing: '0.1rem',
    textTransform: 'uppercase',
    borderRadius: '2rem',
    backgroundColor: 'rgba(4, 204, 80, 1)',
    '&:hover': {
      backgroundColor: 'rgba(4, 204, 80, 1)',
    },
  }),
  ...(customvariant === 'containedDark' && {
    textDecoration: 'none',
    minWidth: '7rem',
    color: '#ffffff',
    textTransform: 'uppercase',
    borderRadius: '2rem',
    letterSpacing: '0.1rem',
    backgroundColor: '#04BA49',
    '&:hover': {
      // backgroundColor: '#04BA49' || 'primary',
      backgroundColor: '#04BA49',
      textDecoration: 'none',
    },
  }),
  ...(customvariant === 'colorContained' && {
    backgroundColor: bgColor || 'primary',
    minWidth: '7rem',
    color: '#ffffff',
    letterSpacing: '0.1rem',
    textTransform: 'uppercase',
    borderRadius: '2rem',
    textDecoration: 'none',
    '&:hover': {
      backgroundColor: bgColor || 'primary',
      textDecoration: 'none',
    },
  }),
  ...(customvariant === 'retry' && {
    minWidth: '7.8rem',
    color: '#ffffff',
    letterSpacing: '0.1rem',
    textTransform: 'uppercase',
    borderRadius: '2rem',
    backgroundColor: 'rgba(4, 204, 80, 1)',
    '&:hover': {
      backgroundColor: 'rgba(4, 204, 80, 1)',
    },
  }),
  textWrap: 'nowrap',
  fontWeight: 900,
  textAlign: 'center',
  padding: '1rem 1.5rem',
}));

const CustomButton: React.FC<CustomButtonProps> = ({
  customvariant,
  bgColor,
  children,
  ...props
}) => (
  <StyledButton {...props} customvariant={customvariant} bgColor={bgColor}>
    {children}
  </StyledButton>
);

export default CustomButton;
