// ----------------------------------------------------------------------

export const HEADER = {
  H_MOBILE: 162,
  H_DESKTOP: 162,
  H_DESKTOP_OFFSET: 162 - 16,
};

export const STAFF_HEADER = {
  H_MOBILE: 88,
  H_DESKTOP: 88,
  H_DESKTOP_OFFSET: 88 - 12,
};

export const NAV = {
  W_VERTICAL: 280,
  W_MINI: 88,
};
