import * as yup from 'yup';
import { useForm } from 'react-hook-form';
import { enqueueSnackbar } from 'notistack';
import { yupResolver } from '@hookform/resolvers/yup';
import { useQueryClient } from '@tanstack/react-query';
import { useMemo, useEffect, useCallback } from 'react';

import { LoadingButton } from '@mui/lab';
import {
  Box,
  Card,
  List,
  Stack,
  Button,
  Divider,
  Typography,
  CardContent,
  ListItemText,
} from '@mui/material';

import { removeFalsyValuesFromObject } from 'src/utils/misc';

import { useTranslate } from 'src/locales';
import queryKeys from 'src/constants/query-keys';
import { updateTemplate } from 'src/api/templates';

import FormProvider from 'src/components/hook-form/form-provider';
import { RHFEditor, RHFTextField } from 'src/components/hook-form';

import { ITemplateDetailItem } from 'src/types/templates';

type Props = {
  templateDetails: ITemplateDetailItem;
};

export default function TemplateEditForm({ templateDetails }: Props) {
  const queryClient = useQueryClient();
  const { t } = useTranslate();

  const templateEditFormSchema = yup.object().shape({
    content: yup
      .string()
      .required(t('notifications.content_required'))
      .trim()
      .min(1, t('notifications.content_required')),
    subject: yup
      .string()
      .required(t('notifications.subject_required'))
      .trim()
      .min(1, t('notifications.subject_required')),
  });

  const defaultValues = useMemo(
    () => ({
      subject: templateDetails.subject,
      content: templateDetails.content,
    }),
    [templateDetails],
  );

  const methods = useForm({
    resolver: yupResolver(templateEditFormSchema),
    defaultValues,
  });

  const {
    handleSubmit,
    formState: { isSubmitting, isDirty },
    reset,
  } = methods;

  const onSubmit = handleSubmit(async (data) => {
    try {
      const formatData = {
        id: templateDetails.id,
        ...data,
      };

      await updateTemplate(removeFalsyValuesFromObject(formatData));

      queryClient.invalidateQueries({
        queryKey: [queryKeys.staff.notifications.templates.template],
      });

      await new Promise((resolve) => setTimeout(resolve, 500));
      // reset();
      enqueueSnackbar(t('common.update_success'));
    } catch (error) {
      if (error?.response?.data) {
        enqueueSnackbar(error?.response?.data?.message, { variant: 'error' });
        reset(defaultValues);
      } else {
        enqueueSnackbar(error, { variant: 'error' });
      }
    }
  });

  const handleReset = useCallback(() => {
    reset(defaultValues);
  }, [defaultValues, reset]);

  useEffect(() => {
    if (templateDetails) {
      handleReset();
    }
  }, [templateDetails, reset, defaultValues, handleReset]);

  const renderText = (
    <List
      sx={{
        '& .MuiListItemText-root': {
          mb: 2,
        },
        '& .MuiDivider-root': {
          my: 2,
        },
      }}
    >
      <ListItemText
        primary={t('common.details')}
        secondary={templateDetails.subject || ''}
        primaryTypographyProps={{
          noWrap: true,
          typography: 'subtitle1',
          fontWeight: 900,
        }}
        secondaryTypographyProps={{
          mt: 0.5,
          component: 'span',
          alignItems: 'center',
          typography: 'body2',
          color: 'text.disabled',
          display: 'inline-flex',
        }}
      />
      <Divider />
      <ListItemText
        primary={t('notifications.dynamic_fields')}
        secondary={templateDetails.dynamicFields || ''}
        primaryTypographyProps={{
          noWrap: true,
          typography: 'subtitle1',
          fontWeight: 900,
        }}
        secondaryTypographyProps={{
          mt: 0.5,
          component: 'span',
          alignItems: 'center',
          typography: 'body2',
          color: 'success.main',
          display: 'inline-flex',
        }}
      />
    </List>
  );

  const action = (
    <Stack direction="row" spacing={1.5} justifyContent="end">
      <LoadingButton
        type="submit"
        variant="contained"
        color="success"
        size="medium"
        loading={isSubmitting}
        disabled={!isDirty}
      >
        {t('common.save')}
      </LoadingButton>
      <Button variant="outlined" size="medium" onClick={handleReset} disabled={!isDirty}>
        {t('common.cancel')}
      </Button>
    </Stack>
  );

  return (
    <FormProvider methods={methods} onSubmit={onSubmit}>
      <Box
        sx={{
          display: 'grid',
          gridTemplateColumns: {
            xs: '1fr',
            md: '1fr 2fr',
          },
          gap: 3,
          width: '100%',
        }}
      >
        <Stack>{renderText}</Stack>
        <Stack spacing={3}>
          <Card>
            <CardContent>
              <Stack spacing={3}>
                <RHFTextField
                  name="subject"
                  size="medium"
                  fullWidth
                  placeholder={t('notifications.test_completed')}
                />

                <Typography variant="subtitle2" fontWeight={900}>
                  {t('common.content')}
                </Typography>
                <Box>
                  <RHFEditor
                    simple
                    name="content"
                    placeholder={t('notifications.write_something')}
                    sx={{
                      minHeight: 402,
                      '& .ql-editor': {
                        bgcolor: 'transparent',
                      },
                    }}
                  />
                </Box>
              </Stack>
            </CardContent>
          </Card>
          {action}
        </Stack>
      </Box>
    </FormProvider>
  );
}
