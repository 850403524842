import { Theme } from '@mui/material/styles';

// ----------------------------------------------------------------------

export function backdrop(theme: Theme) {
  return {
    MuiBackdrop: {
      styleOverrides: {
        root: {
          backgroundColor: 'rgba(0,0,0,0.8)',
        },
        invisible: {
          background: 'transparent',
        },
      },
    },
  };
}
