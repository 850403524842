import axios from 'axios';

import { paths } from 'src/routes/paths';

import { createFileUploadLink } from 'src/api/utils';
import { createQuestion, updateQuestion } from 'src/api/question';

import { IQuestionItem, IQuestionType, IQuestionAnswer } from 'src/types/question';

export const uploadFile = async (
  media: File,
  mimeType: string,
  currentTab: string,
  enqueueSnackbar: (message: string, options: any) => void,
): Promise<string> => {
  try {
    if (media) {
      const {
        data: { data: presignedPostData },
      } = await createFileUploadLink({
        mimeType,
        itemType: currentTab,
      });

      const formData = new FormData();
      Object.keys(presignedPostData.fields).forEach((key) => {
        formData.append(key, presignedPostData.fields[key]);
      });
      formData.append('file', media);
      await axios({
        method: 'post',
        url: presignedPostData.url,
        data: formData,
        headers: {
          'Content-Type': `multipart/form-data;`,
        },
      });

      return presignedPostData.fields.key;
    }
    return '';
  } catch (error) {
    if (error.message) {
      enqueueSnackbar(error.message, { variant: 'error' });
    }
    return '';
  }
};

export const processMediaAndMimeType = async (
  data: any,
  editingQuestionMediaTab: string,
  QUESTION_MEDIA_TYPES: any,
  currentTab: string,
  enqueueSnackbar: (message: string, options: any) => void,
) => {
  const { mediaPath, externalUrl, isExternal, mimeType } = data;
  let mediaAndMimeType: Record<string, any> = {};

  if ((mediaPath || externalUrl) && mimeType) {
    const uploadPath =
      mediaPath?.preview && !isExternal
        ? await uploadFile(mediaPath, mimeType, currentTab, enqueueSnackbar)
        : (mediaPath ?? externalUrl);

    let { dbField } = QUESTION_MEDIA_TYPES.image;

    Object.values(QUESTION_MEDIA_TYPES).some(({ accept, dbField: field }: any) => {
      if (mimeType in accept) {
        dbField = field;
        return true;
      }
      return false;
    });

    mediaAndMimeType = {
      ...(editingQuestionMediaTab && {
        [QUESTION_MEDIA_TYPES[editingQuestionMediaTab]?.dbField]: null,
      }),
      [dbField]: uploadPath,
      mimeType,
    };
  } else if (editingQuestionMediaTab) {
    mediaAndMimeType = {
      [QUESTION_MEDIA_TYPES[editingQuestionMediaTab]?.dbField]: null,
      mimeType: null,
    };
  }

  return mediaAndMimeType;
};

export const processAnswers = (answers: any[], questionType: IQuestionType) => {
  let totalPoints = 0;
  const modifiedAnswersObject: IQuestionAnswer[] = answers.map((answer) => {
    const { text, negativePoint, positivePoint } = answer;
    if (questionType === 'multiple') {
      totalPoints += +positivePoint;
    } else if (totalPoints < positivePoint) {
      totalPoints = +positivePoint;
    }
    return {
      text,
      points: positivePoint || negativePoint,
    };
  });

  return { modifiedAnswersObject, totalPoints };
};

export const handleTestQuestionSave = (
  finalData: IQuestionItem,
  setDndItems: React.Dispatch<React.SetStateAction<any>>,
  parentMethods: any,
) => {
  const updatedIndependentQuestions: any = [];
  const updatedSections: any = [];

  setDndItems((prevItems: any) => {
    const prevItemsCopy = JSON.parse(JSON.stringify(prevItems));
    prevItemsCopy?.some((item: Record<string, any>, i: string | number) => {
      if (item?.questionType) {
        const _id = item?._tempId ? '_tempId' : 'id';
        if (item?.[_id] === finalData?.[_id as keyof {}]) {
          prevItemsCopy[i] = finalData;
          return true;
        }
      }

      if (item?.questions) {
        item?.questions?.some(
          (q: { id: string | undefined; _tempId: string | undefined }, j: string | number) => {
            const _id = q?._tempId ? '_tempId' : 'id';
            if (q?.[_id] === finalData?.[_id as keyof {}]) {
              prevItemsCopy[i].questions[j] = finalData;
              return true;
            }
            return false;
          },
        );
      }
      return false;
    });
    updatedIndependentQuestions.push(
      ...prevItemsCopy.filter((q: Record<string, any>) => q.questionType),
    );
    updatedSections.push(...prevItemsCopy.filter((q: Record<string, any>) => q.questions));
    return prevItemsCopy;
  });

  if (parentMethods) {
    parentMethods.setValue('independentQuestions', updatedIndependentQuestions);
    parentMethods.setValue('sections', updatedSections);
  }
};

export const handleQuestionBankSave = async (
  finalData: IQuestionItem,
  currentQuestion: IQuestionItem | undefined,
  queryClient: any,
  reset: () => void,
  enqueueSnackbar: (message: string, options: any) => void,
  t: (key: string) => string,
  router: any,
) => {
  if (currentQuestion?.id) {
    await updateQuestion(finalData);
  } else {
    await createQuestion(finalData);
  }

  queryClient.invalidateQueries({ queryKey: ['questions'] });
  await new Promise((resolve) => setTimeout(resolve, 500));
  reset();
  enqueueSnackbar(currentQuestion?.id ? t('common.update_success') : t('common.create_success'), {
    variant: 'success',
  });
  router.push(paths.staff.test.questions.root);
};
